<template>
  <div class="item_module">
    <div>
      <div style="text-align: left;margin-bottom:10px;" class="bor_line">
        <div class="titlebottom">{{title}}</div> 
      </div>
      <template v-for="(item,index) in pageData">
        <div class="item_container" :key="index+'_temp'">
            <span class="item_icon"><i class="el-icon-caret-right" style="color: #C4C4C4;margin-right: 8px;"/></span>
            <span class="item_content" :title="item.newsTitle" @click="showDetail(item)">{{item.name}}</span>
            <span class="item_date">{{item.contentDate}}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: ['title','url','type','parent','pageSize'],
  data() {
    return {
      pageData:[],
      types:{'intro':1,'work':2,'busy':3,'news':4,'notice':5,'law': 6},
      types1:[
        {
          name:'机构简介',
          index: 'intro',
          id:'1'
        },{
          name:'站务公开',
          index: 'work',
          id:'2'
        },{
          name:'引航业务',
          index: 'busy',
          id:'3'
        },{
          name:'新闻动态',
          index: 'news',
          id:'4'
        },{
          name:'通知公告',
          index: 'notice',
          id:'5'
        },{
          name:'引航法规',
          index: 'law',
          id:'6'
        }
      ],
    }
  },
  mounted(){
    console.log('mounted',this.type)
    this.pageData = []
    if(!this.pageSize){
      this.pageSize = 10
    }
    let param = {}
    param.contentType = this.types[this.type];
    param.pageNum = 1;
    param.limit = 5;
    param.pageSize = this.pageSize;
    this.axios.post('/api/v2/official/getWebContentList',param).then(res=>{
      if(res && res.data && res.data.data && res.data.data.contentList){
        res.data.data.contentList.forEach(item=>{
          this.pageData.push(item)
        })
      }
    })
  },
  methods : {
    showMore(){
      let param = {}
      param.type = this.type
      param.title = this.title
      param.parent = this.parent
      this.$emit('more',param)
    },
    showDetail(row){
      let param = {}
      param.type = this.type
      param.title = this.title
      param.parent = this.parent
      this.$emit('detail',row,param)
    }
  }
}
</script>

<style>
.more{
	color:#666;
	line-height:30px;
	font-size:12px;
  margin-right: 10px;
}
.more:hover,ul.list1 li a:hover{
	color:#3A85FF;
}
.fr{
	float:right;
}
.bor_line{
	border-bottom:1px solid #388BDB;
}
.titlebottom{
	display:inline-block; 
  width:136px; 
  height:62px; 
  color:#fff;  
  font-size: 16px;
  font-weight: bold;
  line-height:48px;
  text-align:center;
  background-image: url("../assets//images/title_bg.png");
  background-repeat: no-repeat;
  background-size: 136px 62px;
  margin-bottom: -16px;
}

.item_module {
  width: 100%;
  min-height: 324px;
  display:inline-block;
}
.item_container {
  margin-top: 16px;
  display: flex;
  height: 30px;
  line-height: 30px;
}
.item_container .item_icon {  
  width: 25px;
}
.item_container .item_content {
  width: 100%;
  white-space: nowrap;
  text-align: left;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}
.item_content:hover {
	color:#3A85FF;
  cursor: pointer;
}
.item_container .item_date {
  font-size: 16px;
  width: 120px;
  text-align: right;
}
</style>
