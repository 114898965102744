<template>
  <div class="item_detail">
    <div style="width: 100%;text-align: center;">
      <div style="color: #3277B7;font-size: 20px;font-weight: 600;">“坦诚交流，竭诚服务”</div>
      <div style="color:#363636;font-size: 18px;font-weight: 600;margin-top: 8px;">湛江港引航站，将一如既往克服困难，全力以赴做好优质的引航保障服务。</div>
    </div>
    <div class="message-content">
      <div style="width: 100%;">
        <div style="display: flex;justify-content: space-between;width: 100%;">
          <div style="width: 49%;">
            <div class="item_title">公司名称<span style="color: #DD2915;">(必填项)</span></div>
            <el-input placeholder="" v-model="form.companyName" clearable style="width: 100%;"> </el-input>
          </div>
          <div style="width: 49%;">
            <div class="item_title">联系人<span style="color: #DD2915;">(必填项)</span></div>
            <el-input :class="{ 'no-text-background': !form.userName }" v-model="form.userName" clearable style="width: 100%;"> </el-input>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;">
          <div style="width: 49%;">
            <div class="item_title">联系电话<span style="color: #DD2915;">(必填项)</span></div>
            <el-input placeholder="" v-model="form.userPhone" clearable style="width: 100%;"> </el-input>
          </div>
          <div style="width: 49%;">
            <div class="item_title">联系传真</div>
            <el-input placeholder="" v-model="form.userFax" clearable style="width: 100%;"> </el-input>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;">
          <div style="width: 49%;">
            <div class="item_title">E-mail</div>
            <el-input placeholder="" v-model="form.userEmail" clearable style="width: 100%;"> </el-input>
          </div>
          <div style="width: 49%;">
            <div class="item_title">反馈主题<span style="color: #DD2915;">(必填项)</span></div>
            <el-input placeholder="" v-model="form.messageTitle" clearable style="width: 100%;"> </el-input>
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px;">
          <div class="item_title">反馈内容<span style="color: #DD2915;">(必填项)</span></div>
          <el-input type="textarea" placeholder="" :rows="5" v-model="form.messageInfo" clearable></el-input>
        </div>
        <div style="margin-top: 20px;width: 100%;text-align: center;">
          <el-button class="submit_btn" @click="submitMessage">提交留言</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailPage',
  props: ['title','url','detail'],
  data() {
    return {
      form:{
        companyName: '',
        userName: '',
        userPhone: '',
        userFax: '',
        userEmail: '',
        messageTitle: '',
        messageInfo: '',
      },
      pageData:[]
    }
  },
  mounted(){
  },
  methods : {
    submitMessage(){
      if(!this.form.companyName){
        this.$message({
          showClose: true,
          message: '请填写公司名称',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.userName){
        this.$message({
          showClose: true,
          message: '请填写联系人',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.userPhone){
        this.$message({
          showClose: true,
          message: '请填写联系电话',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.messageTitle){
        this.$message({
          showClose: true,
          message: '请填写反馈主题',
          type: 'warning'
        });
        return false;
      }
      if(!this.form.messageInfo){
        this.$message({
          showClose: true,
          message: '请填写反馈内容',
          type: 'warning'
        });
        return false;
      }
      this.axios.put('/api/v2/official/addMessageInfo',this.form).then(res=>{
        this.$message({
          showClose: true,
          message: '提交成功',
          type: 'success'
        });
        this.form = {}
      })
    },
    getData(param){
      getData(param)
    },

  }
}
</script>

<style scoped>
.no-text-background {
  background-color: #ffffff !important; /* 你可以根据需要设置背景色 */
}
.submit_btn{
  background: #3277B7;
  color: #fff;
  border-radius: 100px;
  height: 36px;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
}
.message-content{
  width: 100%;
  display: flex;
  margin-top: 50px;
}
.message-content .el-input__inner{
  width: 370px !important;
  height: 70px !important;
}
.detail_title{
  font-size: 30px;
  text-align: center;
  margin: 30px 0 30px 0;
}
.detail_date {
  overflow: hidden;
  color: #666666;
  font-size: 14px;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
  padding-top: 20px;
}
.detail_content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.item_detail {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid #E9EDF5;
}
.item_title{
  color: #363636;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color: #F1F1F1;
    background-image: none;
    border-radius: 0;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
::v-deep .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: #F1F1F1;
    background-image: none;
    border: 1px solid #F1F1F1;
    border-radius: 0;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}
</style>
