<template>
  <div id="app">
    <!-- <div class="header-top">
      <div class="header-item" style="margin-left: 60px">
        <i class="el-icon-message" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;"><a href="http://www.zjpilot.com">www.zjpilot.com</a></span>
      </div>
      <div class="header-item">
        <i class="el-icon-phone" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;"><a href="tel:0759-2250770">0759-2250770</a></span>
      </div>
      <div class="header-item">
        <i class="el-icon-location" style="font-size: 16px;color: #3A85FF;"></i><span style="margin-left: 8px;">广东省湛江市霞山区友谊路1号海港大厦11-13楼</span>
      </div>
    </div> -->
    <!-- bannr图 大图 -->
    <div class="banner-section flex-column-center" id="banner-section" :style="{backgroundImage:'url('+bannerUrl+')',backgroundRepeat:'no-repeat',backgroundSize: '100% 367px'}">
      <img src="./assets/images/logo-text.png" style="width: 281px;height: 186px;"/>
      <div class="business_btn">
        <img src="./assets/images/btn-icon1.png" style="width: 20px;height: 20px;"/>
        <a href="http://zhanjiang.smartpilot.cn/" style="margin-left: 8px;color: #FFF;text-decoration: none;" target="view_window">进入业务系统</a>
      </div>
    </div>
    <!-- 首页内容 -->
    <div id="website-content">
      <!-- 顶部菜单 -->
      <div class="menu_row">
        <el-menu :default-active="selectIndex" @select="handleSelect" class="el-menu-demo" mode="horizontal" background-color="#3277B7" text-color="#fff" active-text-color="#fff" active->
          <el-menu-item class="menu-item" index="index">
            <img src="./assets/images/icons/menu1.png" class="menu_icon"/>
            首页
          </el-menu-item>
          <el-menu-item class="menu-item" index="work">
            <img src="./assets/images/icons/menu3.png" class="menu_icon"/>
            站务公开
          </el-menu-item>
          <el-menu-item class="menu-item" index="intro">
            <img src="./assets/images/icons/menu2.png" class="menu_icon"/>
            关于我们
          </el-menu-item>
          <!-- <el-menu-item class="menu-item" index="busy">引航业务</el-menu-item>
          <el-menu-item class="menu-item" index="news">新闻动态</el-menu-item>
          <el-menu-item class="menu-item" index="notice">通知公告</el-menu-item> -->
          <el-menu-item class="menu-item" index="message">
            <img src="./assets/images/icons/menu4.png" class="menu_icon"/>
            留言互动
          </el-menu-item>
        </el-menu>
      </div>
      <div v-if="selectIndex!='index'" style="margin-top:15px;margin-bottom:15px;padding: 0 18.7%;">
        <el-breadcrumb separator="/" class="my-el-breadcrumb">
          <el-breadcrumb-item>
            <i class="el-icon-location" style="font-size: 14px;margin-right: 8px;color: #A2BBC2;"/>
            <a href="/">首页</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item><a href="#" @click="gotoMenu1">{{getMenu1()}}</a></el-breadcrumb-item>
          <!-- <el-breadcrumb-item v-if="selectIndex=='more'||selectIndex=='detail'"><a href="#" @click="gotoMenu2">{{getMenu2()}}</a></el-breadcrumb-item> -->
          <el-breadcrumb-item v-if="selectIndex=='detail'">{{selectDetail?selectDetail.name:''}}</el-breadcrumb-item>
          <el-breadcrumb-item></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 标题栏 -->
      <div style="display:flex;margin-top:24px;position:relative;z-index:99998;padding: 0 18.7%;" class="layout_div">
        <div v-if="selectIndex=='index'">
          <div style="display:flex;">
            <ImagePage style="width:486px;height:319px" />
            <HomePageMulti title="新闻动态" @more="onmore" @detail="ondetail" :pageSize="6" parent="news" type="news" style="width:690px;margin-left:25px;height:319px" />
          </div>
          <div style="margin-top: 24px;">
            <img src="./assets/images/flag.png" style="width: 100%;height: 76px;"/>
          </div>
          <div style="display:flex;margin-top:25px;">
            <HomePage title="引航法规" @more="onmore" @detail="ondetail" :pageSize="6" parent="law" type="law" style="width:calc((100% - 25px)/2);" />
            <HomePage title="引航业务" @more="onmore" @detail="ondetail" :pageSize="6" parent="busy" type="busy" style="width:calc((100% - 25px)/2);margin-left:25px;" />
            <!-- <HomePage title="通知公告" @more="onmore" @detail="ondetail" :pageSize="6" parent="notice" type="notice" style="width:320px;margin-left:25px;" /> -->
          </div>
        </div>
        <div v-else-if="selectIndex=='intro'">
          <div>
            <HomePage title="关于我们" @more="onmore" @detail="ondetail" :pageSize="5" parent="intro" type="intro" style="width:100%;" />
          </div>
        </div>
        <div v-else-if="selectIndex=='work'">
          <div>
            <div>
              <HomePage title="站务公开" @more="onmore" @detail="ondetail" :pageSize="5" parent="work" type="work" style="width:100%;" />
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='busy'">
          <div>
            <div>
              <div>
                <HomePage title="引航业务" @more="onmore" @detail="ondetail" :pageSize="5" parent="busy" type="busy" style="width:100%;" />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='news'">
          <div>
            <div>
              <div>
                <div>
                  <HomePage title="新闻动态" @more="onmore" @detail="ondetail" :pageSize="5" parent="news" type="news" style="width:100%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='notice'">
          <div>
            <div>
              <div>
                <div>
                  <HomePage title="通知公告" @more="onmore" @detail="ondetail" :pageSize="5" parent="notice" type="notice" style="width:100%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectIndex=='message'">
          <LeaveMessagePage title="留言互动" @more="onmore" @detail="ondetail" :detail="selectDetail" parent="message" type="message" style="width:100%;" />
        </div>
        <div v-else-if="selectIndex=='more'">
          <ListPage @more="onmore" @detail="ondetail" :title="selectMoreTitle" :parent="selectMoreIndex" :type="selectMoreType" style="width:100%;" />
        </div>
        <div v-else-if="selectIndex=='detail'">
          <DetailPage title="引航动态" @more="onmore" @detail="ondetail" :detail="selectDetail" style="width:100%;" />
        </div>
        <div v-else>
          <div />
        </div>
      </div>
      <!-- <div style="margin-top:100px;margin-bottom:100px;">
        <div style="margin-top:10px;">版权所有：湛江港引航站 | 维护：合一网络科技有限公司</div>
        <div style="margin-top:10px;">地址：广东省湛江市霞山区友谊路1号海港大厦11-13楼</div>
        <div style="margin-top:10px;">邮编：524027 | 备案序号：粤ICP备14038585号-1</div>
        <div style="margin-top:10px;">电话：0759-2250770 | 传真：0759-2250770</div>
      </div> -->
    </div>
    <div class="footer-content">
      <div style="padding: 0 18.7%;">
        <el-carousel :interval="4000" type="card" height="150px" indicator-position="none">
          <el-carousel-item v-for="item in picList" :key="item">
            <img :src="item.src" style="height: 150px;" @click="openUrl(item.url)"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="info_row" style="margin-top: 24px;">
        <span>首页</span>
        <span style="margin-left: 12px;cursor: pointer;" @click="handleSelect('intro', '')">| 关于我们</span>
        <span style="margin-left: 12px;cursor: pointer;" @click="openUrl('http://zhanjiang.smartpilot.cn/html/diaoduyilan')">| 引航计划</span>
        <span style="margin-left: 12px;cursor: pointer;" @click="handleSelect('news', '')">| 新闻动态</span>
        <span style="margin-left: 12px;cursor: pointer;" @click="openUrl('http://zhanjiang.smartpilot.cn/')">| 智慧港航云服务平台</span>
      </div>
      <div class="bottom_container">
        <div>
          <span style="margin-right: 8px;">商务合作请留下您的联系方式，我们会联系您 </span>
          <div class="word-btn" target="view_window" style="cursor: pointer;" @click="handleSelect('message', '')">互动留言</div>
        </div>
        <div style="margin-top: 20px;">
          <span>版权所有 © Copyright 2021 zjpilot.com All rights reserved 外链部</span>
          <span style="margin-left: 20px;">主办：湛江引航站</span>
          <span style="margin-left: 20px;"><a href="https://beian.miit.gov.cn" style="text-decoration: none;color: #363636;">备案号：粤ICP备14038585号-1</a></span>
        </div>
        <div style="margin-top: 20px;">
          <span>地址：广东省湛江市霞山区友谊路1号海港大厦11-13楼</span>
          <span style="margin-left: 20px;">邮编：524002</span>
          <span style="margin-left: 20px;">联系电话：0759-2250770</span>
          <span style="margin-left: 20px;">官方网站：www.zjpilot.com</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'
import HomePageMulti from './components/HomePageMulti.vue'
import DetailPage from './components/DetailPage.vue'
import ListPage from './components/ListPage.vue'
import LeaveMessagePage from './components/LeaveMessagePage.vue'
import ImagePage from './components/ImagePage.vue'
import { queryWebContentList } from './api/website'

export default {
  name: 'App',
  components: {
    HomePage, ImagePage, DetailPage, ListPage, LeaveMessagePage, HomePageMulti
  },
  data () {
    return {
      selectIndex: 'index',
      selectMoreIndex: '',
      imgIndex: 0,
      timer: '',
      bannerUrl: require('./assets/images/banner/header-bg.png'),
      images: [
        require('./assets/images/banner/banner-1.png'),
        require('./assets/images/banner/banner-2.png')
      ],
      picList:[
        {src: require('./assets/images/banner/zjgjt.png'), url: 'https://www.zjport.com/'},
        {src: require('./assets/images/banner/gdhsj.png'), url: 'https://gd.msa.gov.cn/aspx/gdmsa/index.aspx'},
        {src: require('./assets/images/banner/jtysj.png'), url: 'https://www.zhanjiang.gov.cn/jtysj/'},
        {src: require('./assets/images/banner/rmzf.png'), url: 'https://www.zhanjiang.gov.cn/'},
        {src: require('./assets/images/banner/zhyhw.png'), url: 'http://www.chinapilotage.org/index.aspx'},
        {src: require('./assets/images/banner/jtb.png'), url: 'https://www.mot.gov.cn/'},
      ],
      types:[
        {
          name:'关于我们',
          index: 'intro',
          id:'1'
        },{
          name:'站务公开',
          index: 'work',
          id:'2'
        },{
          name:'引航业务',
          index: 'busy',
          id:'3'
        },{
          name:'新闻动态',
          index: 'news',
          id:'4'
        },{
          name:'通知公告',
          index: 'notice',
          id:'5'
        }
      ],
      allList: [],
      logoUrl: require('./assets/images/logo.png'),
      footerBgUrl: require('./assets/images/background/forum-title.png'),
      selectMoreType: '',
      selectMoreTitle: '',
      selectMoreTitle: '',
      selectDetail: null,
      detailParam: null,
    }
  },
  created () {
    document.title = '湛江引航站官网'
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  methods: {
    openUrl(url){
      window.open(url)
    },
    queryWebContent(type){
      let query = {}
      query.type = type
      query.pageSize = 10
      query.pageNum = 1
      queryWebContentList(query).then((res) => {
        this.tableData = res.data.records
      })
    },
    changeIndex () {
      // if (this.imgIndex < this.images.length - 1) {
      //   this.imgIndex++
      // } else {
      //   this.imgIndex = 0
      // }
      // this.bannerUrl = this.images[this.imgIndex]
    },
    handleSelect (key, keyPath) {
      console.log('handleSelect', key)
      this.selectMoreType = ''
      this.selectMoreTitle = ''
      this.selectMoreIndex = ''
      this.selectIndex = key
      this.$forceUpdate()
    },
    gotoMenu1 () {
      if (this.selectIndex == 'more' || this.selectIndex == 'detail') {
        if (this.selectMoreIndex == 'intro') {
          this.selectIndex = 'intro'
        } else if (this.selectMoreIndex == 'work') {
          this.selectIndex = 'work'
        } else if (this.selectMoreIndex == 'busy') {
          this.selectIndex = 'busy'
        } else if (this.selectMoreIndex == 'news') {
          this.selectIndex = 'news'
        } else if (this.selectMoreIndex == 'notice') {
          this.selectIndex = 'notice'
        } else if (this.selectMoreIndex == 'message') {
          this.selectIndex = 'message'
        }
        this.selectMoreType = ''
        this.selectMoreTitle = ''
      }
    },
    gotoMenu2 () {
      console.log('menu2', this.detailParam)
      this.selectMoreTitle = this.detailParam.title
      this.selectMoreType = this.detailParam.type
      this.selectMoreIndex = this.detailParam.parent
      this.selectIndex = 'more'
    },
    onmore (param) {
      console.log('onmore', param)
      this.selectMoreTitle = param.title
      this.selectMoreType = param.type
      this.selectMoreIndex = param.parent
      this.selectIndex = 'more'
      this.detailParam = param
    },
    ondetail (data, param) {
      console.log('ondetail', data, param)
      this.selectIndex = 'detail'
      this.selectMoreType = param.type
      this.selectMoreIndex = param.parent
      this.selectDetail = data
      this.detailParam = param
    },
    getMenu1 () {
      let menu1 = ''
      if (this.selectIndex == 'index') {
        menu1 = '首页'
      } else if (this.selectIndex == 'intro') {
        menu1 = '关于我们'
      } else if (this.selectIndex == 'work') {
        menu1 = '站务公开'
      } else if (this.selectIndex == 'busy') {
        menu1 = '引航业务'
      } else if (this.selectIndex == 'news') {
        menu1 = '新闻动态'
      } else if (this.selectIndex == 'message') {
        menu1 = '留言互动'
      } else if (this.selectIndex == 'notice') {
        menu1 = '通知公告'
      } else if (this.selectIndex == 'more' || this.selectIndex == 'detail') {
        if (this.selectMoreIndex == 'intro') {
          menu1 = '关于我们'
        }if (this.selectMoreIndex == 'law') {
          menu1 = '引航法规'
        } else if (this.selectMoreIndex == 'work') {
          menu1 = '站务公开'
        } else if (this.selectMoreIndex == 'busy') {
          menu1 = '引航业务'
        } else if (this.selectMoreIndex == 'news') {
          menu1 = '新闻动态'
        } else if (this.selectMoreIndex == 'message') {
          menu1 = '留言互动'
        } else if (this.selectMoreIndex == 'notice') {
          menu1 = '通知公告'
        }
      }
      return menu1
    },
    getMenu2 () {
      let menu2 = ''
      if (this.selectMoreType == 'law') {
        menu2 = '引航法规'
      } else if (this.selectMoreType == 'flow') {
        menu2 = '业务流程'
      } else if (this.selectMoreType == 'hr') {
        menu2 = '人事招聘'
      } else if (this.selectMoreType == 'news') {
        menu2 = '新闻动态'
      } else if (this.selectMoreType == 'study') {
        menu2 = '能力建设'
      } else if (this.selectMoreType == 'intro') {
        menu2 = '关于我们'
      } else if (this.selectMoreType == 'leader') {
        menu2 = '领导成员'
      } else if (this.selectMoreType == 'apply') {
        menu2 = '申请手续'
      } else if (this.selectMoreType == 'rate') {
        menu2 = '费率计算'
      } else if (this.selectMoreType == 'pilot') {
        menu2 = '引航动态'
      } else if (this.selectMoreType == 'notice') {
        menu2 = '通知公告'
      }
      return menu2
    }
  }
}
</script>

<style scoped>
@import '~@/assets/style.scss';
#app {
  width: 100%;
  font-family: Helvetica, sans-serif;
  display: block;
  margin: 0 auto;
}
#website-content {
  width: 100%;
  font-family: Helvetica, sans-serif;
  display: block;
  margin: 0 auto;
}
.el-menu-demo .is-active{
  font-weight: 700 !important;
  color: #FFFFFF !important;
  background: #4C99E0 !important;
}
.menu-item{
  width: 25%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.el-menu {
  border-right: solid 1px #e6e6e6;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 18.7%;
  padding-right: 18.7%;
  background-color: #FFF;
}
body {
  margin: 0;
}
.layout_div div {
  width: 100%;
}
::v-deep .footer-content .el-carousel {
    text-align: center;
    padding: 20px 20px;
    position: relative;
    background: #F2F8FF; 
  }
</style>
