<template>
  <div class="item_detail">
    <div>
      <div class="boder_main">
        <div class="detail_title">
          {{detail.name}}
        </div>
        <div class="detail_date" v-if="detail.contentDate">
          <span>发布时间:{{detail.contentDate}}</span>
        </div>
        <div class="detail_content">
          <div v-html="detail.contentInfo"></div>
          <div v-if="detail.imageUrl && detail.imageUrl.length > 0" style="text-align: left;padding: 10px 30px;">
            <div v-for="(url, index) in detail.imageUrl" :key="index">
              <img :src="url" width="1000px"/>
            </div>
          </div>
          <div v-if="detail.fileUrl && detail.fileUrl.length > 0" style="text-align: left;padding: 10px 30px;">
            <div v-for="(file, index) in detail.fileUrl" :key="index">
              附件{{ index + 1 }}: <el-button type="text" style="font-size: 16px;font-weight: 600;" @click="openFile(file)">{{ file.name }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailPage',
  props: ['title','url','detail'],
  data() {
    return {
      pageData:[]
    }
  },
  mounted(){
    console.log(this.detail, 'detail')
    // let param = {}
    // param.newsType = 1;
    // param.pageNum = 1;
    // this.axios.post('/api/v2/official/getNewsList',param).then(res=>{
    //   console.log('post res ',res)
    //   this.pageData = []
    //   if(res && res.data && res.data.data && res.data.data.newsList){
    //     res.data.data.newsList.forEach(item=>{
    //       this.pageData.push(item)
    //     })
    //   }
    // })
  },
  methods : {
    openFile(file){
      window.open(file.url)
    },
    getData(param){
      getData(param)
    }
  }
}
</script>

<style scoped>
.detail_title{
    font-size: 30px;
    text-align: center;
    margin: 30px 0 30px 0;
    font-weight: 600;
}
.detail_date {
  overflow: hidden;
  color: #666666;
  font-size: 14px;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
  padding-top: 20px;
}
.detail_content {
  margin: 50px 0px;
  color: #666666;
  font-size: 20px;
}
.item_detail {
  width: 100%;
  height: 100%;
  border: 1px #dddddd solid;
  padding: 20px 0px;
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
}
</style>
